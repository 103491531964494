<template>
  <div class="px-3">
    <b-navbar class="mb-2 py-1 border-bottom">
      <b-navbar-brand>Buying Agents</b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-nav-item @click="()=>{$router.go(-1)}" link-classes="text-decoration-underline">
          <b-icon-list></b-icon-list> Buyers List
        </b-nav-item>
        <b-button
          v-b-popover.hover.top="wallet.balance.toLocaleString()"
          variant="link"
          class="text-decoration-none text-primary"
        >
          Wallet Balance: {{ std_fig(wallet.balance) }}
        </b-button>
        <b-nav-text></b-nav-text>
        <b-nav-item
          @click="
            () => {
              this.$bvModal.show('admin-wallet');
            }
          "
        >
          <b-icon-plus></b-icon-plus> Credit Wallet
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <router-view></router-view>
    <admin-wallet @credit-wallet="credit_wallet"></admin-wallet>
  </div>
</template>
<script>
import { axiosApi, OFFTAKER_WALLET,std_fig } from ".";
import { Wallet } from "./accounting";
import AdminWallet from "./AdminWallet.vue";
import { bus } from '../../../../main';

export default {
  components: { AdminWallet },
  data() {
    return {
      wallet: new Wallet("", 0),
      std_fig,
    };
  },
  computed: {
    projectId() {
      return this.$store.state.project_id;
    },
  },
  mounted() {
    this.get_wallet();
    bus.$on("debit-offtaker",(amount)=>{
      this.wallet.withdraw(amount);
    })
    bus.$on("credit-offtaker",(amount)=>{
      this.wallet.deposit(amount);
    });
  },
  methods: {
    async get_wallet() {
      const res = await axiosApi({
        url: OFFTAKER_WALLET(this.projectId) + "/",
      });
      const wallet = res.data;
      this.wallet = new Wallet(wallet.id, wallet.balance);
    },
    /**
     * @param {number} amount
     */
    credit_wallet(amount) {
      this.wallet.deposit(amount);
    },
    /**
     * @param {number} amount
     * @returns void
     */
    debit_wallet(amount){
      this.wallet.withdraw(amount);
    }
  },
};
</script>