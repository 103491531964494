<template>
  <b-modal id="admin-wallet" title="Credit Wallet" hide-footer>
    <b-form @submit.prevent="credit_wallet">
      <!-- Using components -->
      <b-input-group prepend="Amount in UGX" class="mt-3">
        <b-form-input type="number" v-model="amount" :min="0" required class="mr-1"></b-form-input>
        <b-input-group-append>
          <b-button variant="outline-success ml-1" type="submit">Approve</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form>
  </b-modal>
</template>
<script>
import { axiosApi, OFFTAKER_WALLET } from ".";
export default {
  data(){
      return {
          amount:0
      }
  },
  computed: {
    projectId() {
      return this.$store.state.project_id;
    },
  },
  methods: {
    async credit_wallet() {
      const amount = parseInt(this.amount);
      const form = new FormData();
      form.set("amount",amount);
      const res = await axiosApi({
        url: OFFTAKER_WALLET(this.projectId) + "/:credit",
        method: "post",
        data:form
      });
      this.$emit("credit-wallet",amount);
      setTimeout(()=>{this.$bvModal.hide("admin-wallet");},3000);
      return res.data;
    },
  },
};
</script>